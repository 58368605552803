import React, { useEffect, useState } from "react"
import {
  Button,
  Main,
  Row,
  RowAndSpaceBetween,
  SubTitleSection,
  Text,
  TextButton,
  TitleMain,
} from "../../../atomics"
import { URL_APP_ANDROID, URL_APP_IOS } from "../../../../constants"
import Section from "../../../Section"
import { t } from "../../../../utils/t"
import Page from "../../Page"
import { useLanguage } from "../../../../hooks"
import { getQueryParam } from "../../../../utils/query"
import { FEATURES, IMAGES } from "./data"
import { RiVipCrownFill } from "react-icons/ri"

function Produtividade() {
  const { language } = useLanguage(`pt`)
  const [src, setSrc] = useState("")

  useEffect(() => {
    setSrc(getQueryParam("utm_source"))
  }, [])

  const sendEvent = event => {
    if (window) {
      if (window.fbq) window.fbq("track", event)
      if (window.gtag) window.gtag("event", event)
    }
  }

  const replaceUtmSource = url => {
    if (src) return url.replace("siteFlynow", src)
    return url
  }

  return (
    <Page
      title={t("flynow_produtividade", language)}
      imageUrl={require("../../../../assets/images/screen-flynow.png")}
      pageId="flynow_produtividade"
      description="A sua produtividade a um download de distância. Com o App Flynow - Produtividade, você tem acesso aos melhores métodos de gestão de tarefas, hábitos e metas para fazer o seu dia render, criar hábitos que realmente duram e estabelecer metas eficazes para alcançar seus sonhos e objetivos. Aplicativo Flynow - Produtividade, gestão de tarefas, rastreamento de hábitos e criação de metas SMART"
    >
      <Main>
        <div>
          <TitleMain>{t("home_title", language)}</TitleMain>
          <Text style={{ marginTop: 10 }}>{t("home_desc", language)}</Text>

          <Row style={{ marginTop: 30 }}>
            <Button
              href={replaceUtmSource(URL_APP_ANDROID)}
              onClick={() => sendEvent("download_app_prod_android")}
            >
              <img
                src={require("../../../../assets/images/playstore.png")}
                alt="playstore"
                className="icon-store"
              />
              <TextButton>{t("button_download_android", language)}</TextButton>
            </Button>
            <Button
              style={{ marginLeft: 10 }}
              href={replaceUtmSource(URL_APP_IOS)}
              onClick={() => sendEvent("download_app_prod_ios")}
            >
              <img
                src={require("../../../../assets/images/applestore.png")}
                alt="applestore"
                className="icon-store"
              />
              <TextButton>{t("button_download_ios", language)}</TextButton>
            </Button>
          </Row>
          <Row style={{ marginTop: 10 }}>
            <Button
              onClick={() => sendEvent("download_app_prod_web")}
              href="/plataforma-web"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={require("../../../../assets/images/web.png")}
                alt="web"
                className="icon-store"
              />
              <TextButton>{t("button_download_web", language)}</TextButton>
            </Button>
            <Button
              onClick={() => sendEvent("download_app_prod_extension")}
              href="/apps/productivity/premium"
              target="_blank"
              rel="noopener noreferrer"
              style={{ marginLeft: 10 }}
            >
              <RiVipCrownFill color="white" size={30}
              />
              <TextButton>
                {t("button_version_premium", language)}
              </TextButton>
            </Button>
          </Row>
        </div>
        <img src={IMAGES.main} alt="aplicativo" className="img-main" />
      </Main>

      <Section title={t("why_use_our_app", language)} id="funcionalidades">
        {FEATURES.map((feature, index) =>
          index % 2 === 0 ? (
            <div key={feature.title}>
              <RowAndSpaceBetween>
                <div style={{ marginTop: 40, minWidth: 330 }}>
                  <SubTitleSection>{t(feature.id, language)}</SubTitleSection>
                  <Text style={{ marginTop: 30 }}>
                    {t(feature.id + "_desc", language)}
                  </Text>
                </div>
                <img src={feature.img} alt={feature.id} className="img-func" />
              </RowAndSpaceBetween>
            </div>
          ) : (
            <div key={feature.title} style={{ marginTop: 30 }}>
              <RowAndSpaceBetween reverseWrap>
                <img src={feature.img} alt={feature.id} className="img-func" />
                <div style={{ marginTop: 40, minWidth: 330, marginLeft: 15 }}>
                  <SubTitleSection>{t(feature.id, language)}</SubTitleSection>
                  <Text style={{ marginTop: 30 }}>
                    {t(feature.id + "_desc", language)}
                  </Text>
                </div>
              </RowAndSpaceBetween>
            </div>
          )
        )}
      </Section>

      <br />
      <br />
      <Section id="depoimentos" title={t("depositions", language)}>
        <div style={{ marginTop: 30 }}>
          <Text>{t("depo_1", language)}</Text>
          <Text
            bold
            style={{ marginTop: 10, textAlign: "right", fontSize: 22 }}
          >
            - Selena Maiko.
          </Text>
        </div>
        <div style={{ marginTop: 30 }}>
          <Text>{t("depo_2", language)}</Text>
          <Text
            bold
            style={{ marginTop: 10, textAlign: "right", fontSize: 22 }}
          >
            - Carol Correia.
          </Text>
        </div>
        <div style={{ marginTop: 30 }}>
          <Text>{t("depo_3", language)}</Text>
          <Text
            bold
            style={{ marginTop: 10, textAlign: "right", fontSize: 22 }}
          >
            - Livia Rodrigues.
          </Text>
        </div>
        <div style={{ marginTop: 30 }}>
          <Text>{t("depo_4", language)}</Text>
          <Text
            bold
            style={{ marginTop: 10, textAlign: "right", fontSize: 22 }}
          >
            - Renata Rodrigues.
          </Text>
        </div>
        <div style={{ marginTop: 30 }}>
          <Text>{t("depo_5", language)}</Text>
          <Text
            bold
            style={{ marginTop: 10, textAlign: "right", fontSize: 22 }}
          >
            - José Leandro
          </Text>
        </div>
        <div style={{ marginTop: 30 }}>
          <Text>{t("depo_6", language)}</Text>
          <Text
            bold
            style={{ marginTop: 10, textAlign: "right", fontSize: 22 }}
          >
            - Ingryd Martins
          </Text>
        </div>
        <div style={{ marginTop: 30, marginBottom: 30 }}>
          <Text>{t("depo_7", language)}</Text>
          <Text
            bold
            style={{ marginTop: 10, textAlign: "right", fontSize: 22 }}
          >
            - Karine
          </Text>
        </div>
      </Section>
    </Page>
  )
}

export default Produtividade
