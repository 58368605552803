export const getQueryParam =(query)=>{
  const [,queries] = document?.location?.search?.split(query+'=')
  if(queries){
    const [query,] = queries?.split('&')
    return query
  }else{
    return null
  }
}
export const getQueries =()=>{
  return document?.location?.search
}
